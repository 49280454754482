import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { VOID_FN, formatDate } from '../../../../utils';

export function InvestmentsColumns({
	handleItemEditClick = VOID_FN,
	handleItemRemoveClick = VOID_FN,
}) {
	return [
		{
			field: 'investor',
			headerName: 'Investisseur',
			width: 400,
			editable: false,
		},
		{
			field: 'parts',
			headerName: 'parts',
			width: 80,
			editable: false,
		},

		{
			field: 'createdAt',
			headerName: 'date',
			width: 160,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.createdAt, true),
		},

		{
			field: '',
			headerName: 'action',
			editable: false,
			width: 500,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Button variant='outlined' onClick={(e) => handleItemEditClick(e, params.row.id)}>
						Modifier
					</Button>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemRemoveClick(e, params.row)}
					>
						Supprimer
					</Button>
				</>
			),
		},
	] as GridColDef[];
}
