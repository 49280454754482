import { Paper, Typography } from '@mui/material';
import { LayoutBoard } from '../../layouts/LayoutBoard';

export const Conditions = () => {
	return (
		<LayoutBoard>
			<Paper elevation={3} sx={{ m: 2 }} style={{ padding: '20px', marginTop: '20px' }}>
				<Typography variant='h4' gutterBottom>
					Conditions d'utilisation - FACYLY
				</Typography>
				<Typography variant='body1' paragraph>
					Bienvenue sur FACYLY, la plateforme de gestion des investissements. En utilisant notre
					application, vous acceptez les conditions d'utilisation suivantes. Veuillez lire
					attentivement ces termes avant d'utiliser nos services.
				</Typography>
				<Typography variant='body1' paragraph>
					1. <strong>Utilisation du service :</strong> Vous acceptez d'utiliser FACYLY conformément
					à toutes les lois applicables et aux conditions énoncées dans ces termes.
				</Typography>
				<Typography variant='body1' paragraph>
					2. <strong>Confidentialité :</strong> Nous respectons la confidentialité de vos
					informations. Consultez notre politique de confidentialité pour en savoir plus sur la
					collecte et l'utilisation de vos données.
				</Typography>
				{/* ... Ajoutez d'autres conditions ici ... */}
				<Typography variant='body1' paragraph>
					En utilisant FACYLY, vous consentez à ces conditions. Ces termes sont sujets à
					modification. Veuillez consulter régulièrement cette page pour rester informé des mises à
					jour.
				</Typography>
			</Paper>
		</LayoutBoard>
	);
};
