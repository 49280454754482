import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { APP_NAME } from '../constants';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../services/Auth';
import { ROUTES } from '../router';
import AdjustIcon from '@mui/icons-material/Adjust';

export default function Sidebar() {
	const navigate = useNavigate();
	const user = Auth.getUser();
	const menuItems = ROUTES.filter(
		(routeItem) =>
			routeItem.roles && routeItem.roles.length && routeItem.roles.includes(user?.role as number)
	).filter((routeItem) => routeItem.showMenu);

	return (
		<List
			sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
			component='nav'
			aria-labelledby='nested-list-subheader'
			subheader={
				<ListSubheader component='div' id='nested-list-subheader'>
					{APP_NAME}
				</ListSubheader>
			}
		>
			<ListItemButton onClick={() => navigate('/')}>
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary='Accueil' />
			</ListItemButton>
			{menuItems.map((m) => (
				<ListItemButton key={m.id} onClick={() => navigate(m.path)}>
					<ListItemIcon>{m.icon || <AdjustIcon />}</ListItemIcon>
					<ListItemText primary={m.label} />
				</ListItemButton>
			))}
		</List>
	);
}
