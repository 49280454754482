import { SessionItem } from '../../../types/SessionItem';
import { SessionService } from '../../../services/Session';

export class SessionController {
	static createDto(adDto: SessionItem) {
		return adDto;
	}
	static updateDto(adDto: SessionItem) {
		return adDto;
	}

	static async create(data: SessionItem) {
		return await SessionService.create(data);
	}

	static async update(data: SessionItem) {
		return await SessionService.update(data);
	}

	static async delete(id: string) {
		return await SessionService.delete(id);
	}
}
