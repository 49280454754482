import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { VOID_FN } from '../utils';
const TITLE = "Use Google's location service?";
const TEXT =
	'Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.';

export default function ConfirmDialog({
	open = false,
	title = TITLE,
	content = TEXT,
	closeBtnLabel = '',
	okBtnLabel = '',
	onOk = VOID_FN,
	onClose = VOID_FN,
}) {
	const handleClose = (e: any) => {
		e.preventDefault();

		onClose(e);
	};
	const handleOk = (e: any) => {
		e.preventDefault();
		onOk(e);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{closeBtnLabel}</Button>
					<Button onClick={handleOk} autoFocus>
						{okBtnLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
