import { useDispatch } from 'react-redux';
import { Auth } from '../../../services/Auth';
import { updatePageTitle } from '../../../store/appDataSlice';
import { Board1 } from './Board1';
import { Board2 } from './Board2';
import { INVESTOR } from '../../../constants';

export function Board() {
	const dispatch = useDispatch();
	dispatch(updatePageTitle('Tableau de board'));
	return Auth.getUser()?.role === INVESTOR ? <Board1 /> : <Board2 />;
}
