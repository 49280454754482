import { Alert, AlertColor, Snackbar } from "@mui/material";
import { VOID_FN } from "../utils";

export const AlertBox = ({
  severity = "success" as AlertColor,
  handleClose = VOID_FN,
  open = true,
  autoHideDuration = 6000,
  message = ""
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
