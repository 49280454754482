import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { VOID_FN, formatDate, getUserRole } from '../../../utils';

export function UsersColumns({ handleItemEditClick = VOID_FN, handleItemRemoveClick = VOID_FN }) {
	return [
		{
			field: 'firstname',
			headerName: 'Prenom',
			width: 400,
			editable: false,
		},
		{
			field: 'lastname',
			headerName: 'Nom',
			width: 200,
			editable: false,
		},
		{
			field: 'email',
			headerName: 'email',
			width: 200,
			editable: false,
		},

		{
			field: 'role',
			headerName: 'role',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => getUserRole(params.row.role),
		},
		{
			field: 'createdAt',
			headerName: 'création',
			width: 160,
			editable: false,
			renderCell: (params: GridRenderCellParams) =>
				params.row?.createdAt ? formatDate(params.row.createdAt, true) : '-',
		},
		{
			field: '',
			headerName: 'action',
			editable: false,
			width: 500,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemEditClick(e, params.row.id)}
					>
						Détails
					</Button>

					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemRemoveClick(e, params.row)}
					>
						Supprimer
					</Button>
				</>
			),
		},
	] as GridColDef[];
}
