import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileUser } from '../types/user';

const initialState = {
	value: {
		id: '',
		firstname: '',
		lastname: '',
		email: '',
		birthDate: '',
		phone: '',
		role: 0,
	} as ProfileUser,
};

export const profileDataSlice = createSlice({
	name: 'profileData',
	initialState,
	reducers: {
		loadProfileDetails: (state, action: PayloadAction<ProfileUser>) => {
			state.value = action.payload;
		},
	},
});

export const { loadProfileDetails } = profileDataSlice.actions;

export default profileDataSlice.reducer;
