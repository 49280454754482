import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	Checkbox,
	CssBaseline,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';

import { Copyright } from '../../Copyright';
import { Auth } from '../../../services/Auth';
import { LoginUser } from '../../../types/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_LOGIN_ERROR,
	LABEL_LOGIN_SUCCESS,
} from '../../../constants';
import { AlertBox } from '../../AlertBox';
import { RootState } from '../../../store';
import { Logo } from '../../common/Logo/Index';
import { STYLE_AUTH } from './styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;

	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data: any) => {
		setIsLoading(true);
		await Auth.login(data as LoginUser);
		setIsLoading(false);
		if (!!Auth.getToken()) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_LOGIN_SUCCESS,
					open: true,
				})
			);
			navigate(0);
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_LOGIN_ERROR,
					open: true,
				})
			);
		}
	};
	// start : hide/show password
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	// end : hide/show password

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={8}
				sx={{
					backgroundImage: 'url(/bg1.jpg)',
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
						t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Logo />
					<Typography sx={STYLE_AUTH.title}>Connexion</Typography>
					<AlertBox
						handleClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_ERROR, open: false }))}
						severity={severity}
						open={isOpen}
						message={message}
					/>
					<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>
					<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
						<TextField
							margin='normal'
							{...register('email', { required: true })}
							error={Boolean(errors.email)}
							fullWidth
							id='email'
							label='Adresse Email '
							name='email'
							autoComplete='email'
							autoFocus
						/>
						<TextField
							margin='normal'
							{...register('password', { required: true })}
							error={Boolean(errors.password)}
							fullWidth
							name='password'
							label='Mot de passe'
							type={showPassword ? 'text' : 'password'}
							id='password'
							autoComplete='current-password'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='afficher / masquer mot de passe'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<FormControlLabel
							control={<Checkbox value='remember' {...register('remember')} color='primary' />}
							label='Rester connecté'
						/>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
							Connexion
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href='/auth/reset-password' variant='body2'>
									Mot de passe oublié?
								</Link>
							</Grid>
							<Grid item>
								<Link href='/register' variant='body2'>
									Vous n'avez pas de compte ? Créer un compte
								</Link>
							</Grid>
						</Grid>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}
