import React, { useEffect, useState } from 'react';
import { Box, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
} from '../../../constants';
import { RootState } from '../../../store';
import { loadSessionDetails } from '../../../store/sessionDataSlice';
import { mapErrorToApiMessage } from '../../../utils';
import ConfirmDialog from '../../ConfirmDialog';
import { displayConfirm } from '../../../store/confirmDialogSlice';
import { updatePageTitle } from '../../../store/appDataSlice';
import { SessionController } from './SessionController';
import { SessionService } from '../../../services/Session';
import { SessionsColumns } from './SessionsColumns';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { SessionItem } from '../../../types/SessionItem';

export function Sessions() {
	/**
	 * Subscriptions
	 */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	dispatch(updatePageTitle('Sessions'));

	const selectedAdRow = useSelector((state: RootState) => state.sessionData.value);

	const { open: isOpenConfirm } = useSelector((state: RootState) => state.confirmDialog.value);

	/**
	 * States
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	/**
	 * functionnals methodes
	 */

	const remove = async (id: string) => {
		try {
			const resp = await SessionController.delete(id);
			if (resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: 'session supprimée',
						open: true,
					})
				);
			}
		} catch (error: any) {
			console.error(error);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: mapErrorToApiMessage(error.response.data?.message) || 'Suppression echouée',
					open: true,
				})
			);
		}
	};

	const handleItemRemoveClick = (e: any, row: any) => {
		dispatch(loadSessionDetails(row));
		dispatch(displayConfirm({ open: true }));
	};
	const onDialogClose = (e: any) => {
		dispatch(displayConfirm({ open: false }));
	};

	const onDialogOk = async (e: any) => {
		if (selectedAdRow.id) await remove(selectedAdRow.id);
		loadData();
		dispatch(displayConfirm({ open: false }));
	};

	const handleItemEditClick = (e: any, id: string) => {
		e.preventDefault();
		navigate(`/sessions/${id}`);
	};

	const handleItemInvestClick = (e: any, row: any) => {
		e.preventDefault();
		dispatch(loadSessionDetails({ ...row, id: row._id }));
		navigate(`/sessions/${row.id}/investments`);
	};

	const handleItemDuplicationClick = async (e: any, row: any) => {
		e.preventDefault();
		delete row._id;
		delete row.id;
		delete row.createdBy;
		delete row.createdAt;
		delete row.updatedAt;
		delete row.__v;
		const item = { ...row, name: `Copy ${row.name}` };
		const resp = await SessionService.create(item as SessionItem);
		if (resp?.status === 201) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'session'),
					open: true,
				})
			);
			navigate(0);
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_FEMALE_CREATION_ERROR.replace('###', 'session'),
					open: true,
				})
			);
		}
	};

	const loadData = async () => {
		setIsLoading(true);
		const result = await SessionService.getAll();
		if (result?.status === 200) {
			setRows(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};
	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LayoutBoard>
			<Typography variant='h3' sx={{ mt: 2, ml: 2 }}>
				Sessions
			</Typography>
			<ConfirmDialog
				open={isOpenConfirm}
				title='Suppression session'
				content='Voulez-vous vraiment supprimer cette session ?'
				okBtnLabel='Oui'
				closeBtnLabel='Non'
				onClose={onDialogClose}
				onOk={onDialogOk}
			/>
			<Box
				sx={{
					width: '98%',
					backgroundColor: '#fff',
					m: 2,
				}}
			>
				<DataGrid
					autoHeight
					loading={isLoading}
					rows={rows}
					columns={SessionsColumns({
						handleItemEditClick,
						handleItemRemoveClick,
						handleItemInvestClick,
						handleItemDuplicationClick,
					})}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			</Box>

			<Fab
				onClick={() => navigate('/sessions/new')}
				style={{
					position: 'absolute',
					bottom: 35,
					right: 35,
				}}
				color='primary'
				aria-label='add'
			>
				<AddIcon />
			</Fab>
		</LayoutBoard>
	);
}
