import React from "react";
import { Typography , Link} from "@mui/material";
import { APP_NAME, APP_WEBSITE_URL } from "../constants";

export function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href={APP_WEBSITE_URL}> {APP_NAME} </Link>
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
  