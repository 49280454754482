import { useEffect, useState } from 'react';
import { Counter } from '.';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR } from '../../../constants';
import { CountersService } from '../../../services/Counters';
import { useDispatch } from 'react-redux';

export const Counter3 = () => {
	const [counter, setCounter] = useState(0);
	const dispatch = useDispatch();
	const loadData = async () => {
		CountersService.count(3)
			.then((resp: any) => {
				setCounter(resp.data.count);
			})
			.catch((res) => {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: ' Impossible de charger les données statistiques',
						open: true,
					})
				);
			});
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Counter
			title='investissement à venir'
			icon={<PriceChangeIcon sx={{ fontSize: 64 }} />}
			counter={counter}
		/>
	);
};
