import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { ChartService } from '../../services/Chart';
import { useDispatch } from 'react-redux';
import { showAlertBox } from '../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR } from '../../constants';
import { ChartWrapper } from '.';

export function Chart4({ session }: any) {
	const dispatch = useDispatch();
	ChartJS.register(ArcElement, Tooltip, Legend, Colors);
	const [pieChartData, setPieChartData] = useState({
		labels: [],
		data: [],
	} as any);

	const configChartData = {
		labels: pieChartData.labels,
		datasets: [
			{
				id: 2,
				label: 'parts',
				data: pieChartData.data,
			},
		],
	};

	const loadChart4Data = async () => {
		ChartService.chart4(session as string)
			.then((resp: any) => {
				setPieChartData(resp.data);
			})
			.catch((res) => {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Aucune données statistique trouvée',
						open: true,
					})
				);
			});
	};

	useEffect(() => {
		loadChart4Data();
		// eslint-disable-next-line
	}, []);

	return (
		<ChartWrapper title='investissements'>
			{!pieChartData.data.length ? (
				<div style={{ textAlign: 'center' }}> Aucune donnée disponible</div>
			) : (
				<Pie
					data={configChartData}
					options={{
						plugins: {
							legend: {
								position: 'right',
							},
						},
					}}
				/>
			)}
		</ChartWrapper>
	);
}
