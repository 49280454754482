import { LayoutBoard } from '../../layouts/LayoutBoard';
import { Box, Typography } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Counter1 } from '../../common/Counters/Counter1';
import { Counter2 } from '../../common/Counters/Counter2';
import { Counter3 } from '../../common/Counters/Counter3';

export function Board1() {
	return (
		<LayoutBoard>
			<Typography sx={{ fontSize: 18, textAlign: 'center', p: 2 }}>Bienvenue</Typography>
			<Box sx={{ p: 2 }}>
				<Masonry columns={3} spacing={2}>
					<Counter1 />
					<Counter2 />
					<Counter3 />
				</Masonry>
			</Box>
		</LayoutBoard>
	);
}
