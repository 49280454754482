import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { InvestmentService } from '../../../../services/Investment';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
} from '../../../../constants';
import { showAlertBox } from '../../../../store/alertBoxSlice';
import { Button, Grid, LinearProgress, TextField, Box, Typography } from '@mui/material';
import { InvestmentItem } from '../../../../types/InvestmentItem';
import { useParams } from 'react-router-dom';
import { LayoutBoard } from '../../../layouts/LayoutBoard';
import { AxiosError } from 'axios';
import { ServerError } from '../../../../types';
import { SessionService } from '../../../../services/Session';
import { SessionCard } from '../../../common/SessionCard';
import { SessionItem } from '../../../../types/SessionItem';

export function InvestmentFormSelf() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const [session, setSession] = useState({} as SessionItem);
	const params = useParams();
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		createInvestment({ id: params.id, ...data });
		setIsLoading(false);
		//
	};

	const createInvestment = async (data: any) => {
		try {
			const resp = await InvestmentService.selfCreate(
				params.session as string,
				data as InvestmentItem
			);
			if (resp?.status && resp?.status === 201) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'investissement'),
						open: true,
					})
				);
				navigate(`/opportunities`);
			}
		} catch (error) {
			console.error(error);
			const serverError = (error as AxiosError).response?.data as ServerError;
			const message = serverError?.message
				? serverError.message
				: LABEL_FEMALE_CREATION_ERROR.replace('###', 'investissement');
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const loadSessionData = () => {
		SessionService.get(params.session)
			.then((resp: any) => {
				setSession(resp.data);
			})
			.catch((error) => {
				console.error(error);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Erreur  lors du chargement des details la session',
						open: true,
					})
				);
			});
	};

	useEffect(() => {
		loadSessionData();
		// eslint-disable-next-line
	}, []);

	const cardAction = (id: string) => {
		return (
			<Button sx={{ mr: 2 }} type='button' onClick={() => navigate(`/opportunities`)}>
				Opportinités
			</Button>
		);
	};

	return (
		<LayoutBoard>
			{isLoading && (
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid container>
				<Grid item xs={6}>
					<SessionCard session={session} actionComponent={cardAction} />
				</Grid>
				<Grid item xs={6} sx={{ p: 2 }}>
					<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
						<Typography variant='h5' sx={{ p: 2, textAlign: 'center' }}>
							Investissement dans la session {session.name}{' '}
						</Typography>

						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Nombre de parts'
							type='number'
							variant='outlined'
							{...register('parts', { required: true })}
							error={Boolean(errors.parts)}
						/>
						<Grid container>
							<Grid item sx={{ textAlign: 'center', mt: 3 }}>
								<Button sx={{ mr: 2 }} type='submit' variant='contained'>
									Enregistrer
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</LayoutBoard>
	);
}
