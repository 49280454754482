import axios from 'axios';
import { LoginUser, ProfileUser, RegisterUser, User } from '../types/user';
import { APP_API_URL, APP_LOCAL_STORAGE_KEY, CONSOLE_LOGOUT_MESSAGE } from '../constants';
import { jwtDecode } from 'jwt-decode';

export class Auth {
	static async login(user: LoginUser) {
		const { email, password, remember } = user;
		try {
			const response = await axios({
				method: 'post',
				url: APP_API_URL + 'auth/login',
				data: { email, password },
			});
			const {
				data: { access_token },
			} = response;
			Auth.setToken(access_token, remember);
		} catch (error) {
			console.error(error);
			Auth.logout();
		}
	}

	static async register(user: RegisterUser) {
		return await axios.post(APP_API_URL + 'auth/register', user);
	}

	static getUser(): ProfileUser | null {
		if (!Auth.getToken()) return null;
		const decoded: any = jwtDecode(Auth.getToken());
		return decoded ? (decoded as ProfileUser) : null;
	}

	static getToken(): string {
		const localToken = localStorage.getItem(APP_LOCAL_STORAGE_KEY as string);
		const sessionToken = sessionStorage.getItem(APP_LOCAL_STORAGE_KEY as string);
		return localToken || sessionToken || '';
	}

	static setToken(token: string, remember: boolean = false) {
		remember
			? localStorage.setItem(APP_LOCAL_STORAGE_KEY as string, token)
			: sessionStorage.setItem(APP_LOCAL_STORAGE_KEY as string, token);
	}

	static logout() {
		localStorage.clear();
		sessionStorage.clear();
		console.info(CONSOLE_LOGOUT_MESSAGE);
	}

	static async profile() {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'auth/profile',
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
				'Content-Type': 'application/json',
			},
		});
	}

	static async updateProfile(user: ProfileUser) {
		const { firstname, lastname, email, phone, password } = user;
		let userData = { firstname, lastname, email };
		if (password) {
			userData = { ...userData, password } as any;
		}
		if (phone) {
			userData = { ...userData, phone } as any;
		}
		return await axios({
			method: 'POST',
			url: APP_API_URL + 'auth/profile',
			data: userData,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
				'Content-Type': 'application/json',
			},
		});
	}

	static async requestPasswordReset(data: User) {
		return await axios({
			method: 'POST',
			url: APP_API_URL + 'auth/password-reset/request',
			data,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
				'Content-Type': 'application/json',
			},
		});
	}

	static async resetPassword(data: any, token: string) {
		return await axios({
			method: 'POST',
			url: APP_API_URL + 'auth/password-reset/t/' + token,
			data,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
			},
		});
	}

	static async activation(token: string) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'auth/register/confirm/' + token,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
			},
		});
	}
}
