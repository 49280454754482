import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { useEffect, useState } from 'react';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { useDispatch } from 'react-redux';
import { SessionService } from '../../../services/Session';
import { DEFAULT_ALERT_ERROR } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { SessionCard } from '../../common/SessionCard';

export function Opportunities() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [sessions, setSessions] = useState([]);
	const navigate = useNavigate();

	const loadData = async () => {
		setIsLoading(true);
		const result = await SessionService.getAll();
		if (result?.status === 200) {
			setSessions(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	const handleDetailClick = (e: MouseEvent, id: string) => {
		e.preventDefault();
		navigate('/sessions/detail/' + id);
	};

	const handleInvestClick = (e: MouseEvent, id: string) => {
		e.preventDefault();
		navigate('/opportunities/' + id);
	};

	const actionComponent = (id: string) => {
		return (
			<>
				<Button
					title='avoir plus de details sur cette session'
					onClick={(e: any) => handleDetailClick(e, id)}
				>
					Plus de détails
				</Button>
				<Button onClick={(e: any) => handleInvestClick(e, id)} title='miser sur cette opportunité'>
					Investir
				</Button>
			</>
		);
	};
	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<LayoutBoard>
			<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>
			<Typography sx={{ fontSize: 18, textAlign: 'center', pt: 2 }}> Sessions en cours</Typography>

			<Grid container spacing={2} sx={{ p: 2 }}>
				{sessions.map((s: any) => (
					<Grid item xs={4} key={s.id}>
						<SessionCard session={s} actionComponent={actionComponent} />
					</Grid>
				))}
			</Grid>
		</LayoutBoard>
	);
}
