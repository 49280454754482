import { APP_NAME } from '../../../constants';

export const Logo = ({ width = '192', otherProps }: any) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: 18,
				color: 'rgba(81,160,38,1)',
			}}
		>
			<div>
				<img src='/logo.png' width={width} alt='logo' {...otherProps} />
			</div>
			<div>{APP_NAME}</div>
		</div>
	);
};
