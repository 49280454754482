import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserService } from '../../../services/User';
import { DEFAULT_ALERT_ERROR } from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { Grid, LinearProgress, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { UserCard } from '../../common/UserCard';
import { Counter4 } from '../../common/Counters/Counter4';
import { Counter5 } from '../../common/Counters/Counter5';
import { Chart5 } from '../../charts/chart5';

export function UserDetail() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();

	const [user, setUser] = useState({} as any);
	//hooks
	const dispatch = useDispatch();

	const loadDetails = async (id: any) => {
		setIsLoading(true);
		const resp = await UserService.get(id);
		if (resp?.status === 200) {
			setUser(resp.data);
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: ' Impossible de charger les details depuis le serveur',
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		loadDetails(params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LayoutBoard>
			{isLoading && (
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid container sx={{ p: 2 }} spacing={1}>
				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={12}>
							<UserCard user={user} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<Grid container direction='row' justifyContent='space-between' alignItems='center'>
						<Grid item xs={12} md={6}>
							<Counter4 id={params.id} />
						</Grid>
						<Grid item xs={12} md={6}>
							<Counter5 id={params.id} />
						</Grid>
						<Grid item xs={12}>
							<Chart5 id={params.id} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</LayoutBoard>
	);
}
