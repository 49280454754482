import { ProfileUser } from '../../../types/user';
import { UserService } from '../../../services/User';

export class UserController {
	static async create(data: ProfileUser) {
		return await UserService.create(data);
	}

	static async delete(id: string) {
		return await UserService.delete(id);
	}
}
