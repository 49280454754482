import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SessionService } from '../../../services/Session';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_EDIT_ERROR,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
	LABEL_FEMALE_EDIT_SUCCESS,
} from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { Button, Grid, LinearProgress, TextField, Box } from '@mui/material';
import { SessionItem } from '../../../types/SessionItem';
import { updatePageTitle } from '../../../store/appDataSlice';
import { useParams } from 'react-router-dom';
import { loadSessionDetails } from '../../../store/sessionDataSlice';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { DateTimePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fromISOToDatetime, getDatePickerError } from '../../../utils';

export function SessionForm() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();

	const [minDate] = useState(dayjs());
	const [startDateValue, setStartDateValue] = useState(dayjs());
	const [endDateValue, setEndDateValue] = useState(dayjs());
	const [paymentDateValue, setPaymentDateValue] = useState(dayjs());
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [error, setError] = useState<DateValidationError | null>(null);

	const errorMessage = useMemo(() => {
		return getDatePickerError(error);
	}, [error]);

	// const sessionDetails = useSelector((state: RootState) => state.sessionData.value);

	const {
		reset,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		if (params?.id) {
			updateSession({
				...data,
				id: params.id,
				startDate: startDateValue.toISOString(),
				endDate: endDateValue.toISOString(),
				paymentDate: paymentDateValue.toISOString(),
			});
		} else {
			createSession(data);
		}
		setIsLoading(false);
		//
	};

	const createSession = async (data: any) => {
		const resp = await SessionService.create(data as SessionItem);
		if (resp?.status === 201) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'session'),
					open: true,
				})
			);
			resetForm();
			navigate('/sessions');
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_FEMALE_CREATION_ERROR.replace('###', 'session'),
					open: true,
				})
			);
		}
	};

	const updateSession = async (data: any) => {
		const resp = await SessionService.update(data as SessionItem);
		if (resp?.status === 200) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_EDIT_SUCCESS.replace('###', 'session'),
					open: true,
				})
			);
			resetForm();
			navigate('/sessions');
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_EDIT_ERROR + 'session',
					open: true,
				})
			);
		}
	};

	const preFillForm = (session: SessionItem) => {
		const {
			name,
			rate,
			partPrice,
			totalParts,
			startDate,
			endDate,
			paymentDate,
			description,
			closed,
		} = session;
		setValue('name', name);
		setValue('rate', rate);
		setValue('partPrice', partPrice);
		setValue('totalParts', totalParts);
		setValue('startDate', fromISOToDatetime(startDate as string));
		setValue('endDate', fromISOToDatetime(endDate as string));
		setValue('paymentDate', fromISOToDatetime(paymentDate as string));
		setValue('description', description);
		setValue('closed', closed);
	};

	const resetForm = () => {
		reset();
	};

	const handleEndDateChange = (newValue: any) => {
		setEndDateValue(newValue);
	};

	const handlePaymentDateChange = (newValue: any) => {
		setPaymentDateValue(newValue);
	};
	const handleStartDateChange = (newValue: any) => {
		setStartDateValue(newValue);
	};

	useEffect(() => {
		const { id } = params;

		async function loadDetails(id: any) {
			const resp = await SessionService.get(id);
			if (resp?.status === 200) {
				dispatch(loadSessionDetails(resp.data));
				preFillForm(resp.data as SessionItem);
			}
		}
		if (Boolean(id)) {
			dispatch(updatePageTitle("Modification d'une session"));
			loadDetails(id);
		} else {
			dispatch(updatePageTitle("Création d'une session "));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
			<LayoutBoard container={true}>
				<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
					{isLoading && (
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					)}
					Session d'investissement
					<Grid container style={{ marginBottom: 50 }}>
						<Grid item xs={8}></Grid>
						<Grid item xs={4} style={{ textAlign: 'right' }}>
							<Button type='button' onClick={() => navigate('/sessions')} variant='outlined'>
								Retour
							</Button>{' '}
							<Button type='reset' onClick={resetForm} variant='outlined'>
								Annuler
							</Button>{' '}
							<Button type='submit' variant='contained'>
								Enregistrer
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Nom'
								variant='outlined'
								{...register('name', { required: true })}
								error={Boolean(errors.name)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Taux'
								type='number'
								variant='outlined'
								{...register('rate', { required: true })}
								error={Boolean(errors.rate)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Prix U. part'
								type='number'
								variant='outlined'
								{...register('partPrice', { required: true })}
								error={Boolean(errors.partPrice)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Nombre de parts'
								type='number'
								variant='outlined'
								{...register('totalParts', { required: true })}
								error={Boolean(errors.totalParts)}
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<DateTimePicker
								label='Date début'
								minDateTime={minDate}
								ampm={false}
								value={startDateValue}
								{...register('startDate', { required: true })}
								onChange={handleStartDateChange}
								onError={(newError) => setError(newError as DateValidationError)}
								slotProps={{
									textField: {
										helperText: errorMessage,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<DateTimePicker
								label='Date fin'
								value={endDateValue}
								minDateTime={startDateValue}
								{...register('endDate', { required: true })}
								onChange={handleEndDateChange}
								ampm={false}
								onError={(newError) => setError(newError as DateValidationError)}
								slotProps={{
									textField: {
										helperText: errorMessage,
									},
								}}
							/>
						</Grid>
						<Grid item xs={6} md={4}>
							<DateTimePicker
								label='Date de paiement'
								value={paymentDateValue}
								minDateTime={endDateValue}
								{...register('paymentDate', { required: true })}
								onChange={handlePaymentDateChange}
								ampm={false}
								onError={(newError) => setError(newError as DateValidationError)}
								slotProps={{
									textField: {
										helperText: errorMessage,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								multiline
								fullWidth
								label='Description'
								variant='outlined'
								rows={4}
								maxRows={4}
								{...register('description')}
							/>
						</Grid>
					</Grid>
				</Box>
			</LayoutBoard>
		</LocalizationProvider>
	);
}
