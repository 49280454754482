import { Button, LinearProgress, Paper, Typography } from '@mui/material';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../store/appDataSlice';
import { Logo } from '../../common/Logo/Index';
import { AppService } from '../../../services/App';
import { useEffect, useState } from 'react';

export const About = () => {
	const dispatch = useDispatch();
	const [version, setVersion] = useState('inconnue');
	const [isLoading, setIsLoading] = useState(false);
	const readAppVersion = async () => {
		setIsLoading(true);
		AppService.frontVersion()
			.then((resp: any) => {
				console.info(resp.data);
				setVersion(resp.data.version);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	};

	dispatch(updatePageTitle('A propos'));

	useEffect(() => {
		readAppVersion();
	}, []);

	return (
		<LayoutBoard>
			{isLoading && <LinearProgress sx={{ mt: 1 }} />}
			<Paper elevation={3} sx={{ p: '20px', m: 2, mt: '20px' }}>
				<Typography variant='h4' gutterBottom>
					À propos FACYLY
				</Typography>
				<Logo />
				<Typography variant='body1' paragraph>
					Version : {version}
				</Typography>
				<Typography variant='body1' paragraph>
					Notre plateforme offre une solution complète de gestion des investissements, combinant
					simplicité et puissance. Maximisez vos rendements, suivez les performances de votre
					portefeuille et prenez des décisions éclairées grâce à des analyses avancées. Avec une
					interface conviviale et des fonctionnalités de pointe, nous simplifions la gestion
					financière pour les investisseurs novices et chevronnés.
				</Typography>
				<Typography variant='body1' paragraph>
					Chez Facyly, nous sommes déterminés à fournir une expérience utilisateur exceptionnelle
					tout en offrant des outils robustes pour optimiser vos investissements. Découvrez la
					nouvelle ère de la gestion financière avec notre solution.
				</Typography>
				<div>
					<Button href='/cgu'> Voir les CGU</Button>
				</div>
			</Paper>
		</LayoutBoard>
	);
};
