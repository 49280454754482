import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatCurrency, formatDate } from '../../../utils';

export function AllInvestmentsColumns() {
	return [
		{
			field: 'session.name',
			headerName: 'Session',
			width: 500,
			editable: false,
			renderCell: (params: GridRenderCellParams) => params.row.session.name,
		},
		{
			field: 'parts',
			headerName: 'parts',
			width: 80,
			editable: false,
			renderCell: (params: GridRenderCellParams) => `${params.row.parts}/${params.row.totalParts}`,
		},
		{
			field: 'amount',
			headerName: 'Montant (FCFA)',
			width: 120,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatCurrency(params.row.amount, 0),
		},
		{
			field: 'startDate',
			headerName: 'debut',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.session.startDate),
		},
		{
			field: 'enDate',
			headerName: 'fin',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.session.endDate),
		},
		{
			field: 'createdAt',
			headerName: 'date',
			width: 160,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.createdAt, true),
		},
	] as GridColDef[];
}
