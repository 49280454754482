import { LayoutBoard } from '../../layouts/LayoutBoard';
import { Box, Typography } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Chart1 } from '../../charts/chart1';
import { Chart2 } from '../../charts/chart2';
import { Chart3 } from '../../charts/chart3';

export function Board2() {
	return (
		<LayoutBoard>
			<Typography sx={{ fontSize: 18, textAlign: 'center', p: 2 }}>Tableau de bord</Typography>
			<Box sx={{ p: 2 }}>
				<Masonry columns={2} spacing={2}>
					<Chart1 />
					<Chart2 />
					<Chart3 />
				</Masonry>
			</Box>
		</LayoutBoard>
	);
}
