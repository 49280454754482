import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NullableString } from '../types';
export interface InvestmentDetails {
	id: NullableString;
	parts: NullableString;
	investor: NullableString;
	investorName?: NullableString;
}
const initialState = {
	value: {
		id: null,
		parts: null,
		investor: null,
		investorName: null,
	} as InvestmentDetails,
};

export const investmentDataSlice = createSlice({
	name: 'investmentData',
	initialState,
	reducers: {
		loadInvestmentDetails: (state, action: PayloadAction<InvestmentDetails>) => {
			state.value = action.payload;
		},
		getSelectedItem: (state, action: PayloadAction<InvestmentDetails>) => {
			state.value = action.payload;
		},
	},
});

export const { loadInvestmentDetails, getSelectedItem } = investmentDataSlice.actions;

export default investmentDataSlice.reducer;
