import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SessionService } from '../../../services/Session';
import { DEFAULT_ALERT_ERROR } from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { Button, Grid, LinearProgress, Box, CardActions } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { Chart4 } from '../../charts/chart4';
import { SessionCard } from '../../common/SessionCard';

export function SessionDetail() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();

	const [session, setSession] = useState({} as any);
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loadDetails = async (id: any) => {
		setIsLoading(true);
		const resp = await SessionService.get(id);
		if (resp?.status === 200) {
			setSession(resp.data);
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: ' Impossible de charger les details depuis le serveur',
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		loadDetails(params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const actionComponent = (id: string) => (
		<CardActions>
			<Button
				type='button'
				title='miser sur cette opportunité'
				onClick={(e) => {
					e.preventDefault();
					navigate('/opportunities/' + id);
				}}
			>
				Investir maintenant
			</Button>
		</CardActions>
	);

	return (
		<LayoutBoard>
			{isLoading && (
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid container sx={{ p: 2 }}>
				<Grid item xs={4}>
					<SessionCard session={session} actionComponent={actionComponent} />
				</Grid>
				<Grid item xs={8}>
					<Chart4 session={params.id} />
				</Grid>
			</Grid>
		</LayoutBoard>
	);
}
