import { Card, CardContent, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const Counter = ({
	title,
	counter,
	icon = <AccountBalanceIcon sx={{ fontSize: 64 }} />,
	backgroundColor = '#90caf9',
}: any) => {
	return (
		<Card>
			<CardContent sx={{ backgroundColor }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div>
						<Typography
							sx={{
								textAlign: 'right',
								p: 4,
								borderRadius: 2,
								fontWeight: 'bold',
								backgroundColor: 'white',
								opacity: 0.4,
							}}
						>
							{icon}
						</Typography>
					</div>
					<div>
						<Typography sx={{ fontSize: 48, textAlign: 'right', p: 1 }}>{counter}</Typography>
						<Typography sx={{ fontSize: 21, textAlign: 'right', p: 1 }}>{title}</Typography>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};
