import React from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	CssBaseline,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Link,
	Paper,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { Copyright } from '../../Copyright';
import { useState } from 'react';
import { Auth } from '../../../services/Auth';
import { RegisterUser } from '../../../types/user';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_REGISTRATION_CGU,
	LABEL_REGISTRATION_CGU_ERROR,
	LABEL_REGISTRATION_ERROR,
	LABEL_REGISTRATION_SUCCESS,
} from '../../../constants';
import { RootState } from '../../../store';
import Alert from '@mui/material/Alert';
import { AxiosError } from 'axios';
import { ServerError } from '../../../types';
import { Logo } from '../../common/Logo/Index';
import { STYLE_AUTH } from './styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function Register() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset: resetForm,
	} = useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;

	const onSubmit = async (data: any) => {
		setIsLoading(true);
		delete data.confirm_password;
		delete data.accepted_conditions;

		//
		try {
			const resp = await Auth.register(data as RegisterUser);
			if (resp?.status && resp?.status === 201) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: LABEL_REGISTRATION_SUCCESS,
						open: true,
					})
				);
				resetForm();
			}
		} catch (error) {
			console.error(error);
			const serverError = (error as AxiosError).response?.data as ServerError;
			const message = serverError?.message ? serverError.message : LABEL_REGISTRATION_ERROR;
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}

		setIsLoading(false);
	};
	// start : hide/show password
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	// end : hide/show password

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid item component={Paper} elevation={6} square sm={8} md={4}>
				<Box
					sx={{
						my: 2,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Logo />
					<Typography sx={STYLE_AUTH.title}>Créer votre compte</Typography>
					{isOpen && (
						<Box sx={{ width: '100%', marginTop: 2 }}>
							<Alert
								variant='filled'
								onClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_ERROR, open: false }))}
								severity={severity}
							>
								{message}
							</Alert>
						</Box>
					)}
					<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>

					<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete='given-name'
									{...register('firstname', { required: true })}
									error={Boolean(errors.firstname)}
									fullWidth
									type='text'
									id='firstname'
									label='Prénom'
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									{...register('lastname', { required: true })}
									error={Boolean(errors.lastname)}
									fullWidth
									type='text'
									id='lastname'
									label='Nom'
									name='lastname'
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									{...register('email', { required: true })}
									error={Boolean(errors.email)}
									fullWidth
									id='email'
									label='E-mail'
									name='email'
									type='email'
									autoComplete='email'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('phone', { required: true })}
									error={Boolean(errors.phone)}
									fullWidth
									id='phone'
									label='Téléphone'
									name='phone'
									type='text'
									autoComplete='phone'
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('password', { required: true })}
									error={Boolean(errors.password)}
									fullWidth
									label='Mot de passe'
									type={showPassword ? 'text' : 'password'}
									id='password'
									autoComplete='password'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('confirm_password', { required: true })}
									error={Boolean(errors.confirm_password)}
									fullWidth
									name='confirm_password'
									label='Confirmation mot de passe'
									type={showPassword ? 'text' : 'password'}
									id='confirm_password'
									autoComplete='confirm-password'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									required
									error={Boolean(errors.accepted_conditions)}
									component='fieldset'
									sx={{ m: 2 }}
									variant='standard'
								>
									{Boolean(errors.accepted_conditions) && (
										<FormHelperText>{LABEL_REGISTRATION_CGU_ERROR}</FormHelperText>
									)}
									<FormControlLabel
										control={
											<Switch
												value='accepted'
												{...register('accepted_conditions', {
													required: true,
												})}
												color='primary'
											/>
										}
										label={LABEL_REGISTRATION_CGU}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 1, mb: 2 }}>
							Créer un compte
						</Button>
						<Grid container justifyContent='flex-end'>
							<Grid item>
								<Link href='/login' variant='body2'>
									Vous avez déjà un compte ? Connectez-vous
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Grid>
			<Grid
				item
				sm={4}
				md={8}
				sx={{
					backgroundImage: 'url(/bg2.jpg)',
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
						t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			></Grid>
		</Grid>
	);
}
