import React, { useEffect, useState } from 'react';
import { Box, Card, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR, DEFAULT_ALERT_SUCCESS } from '../../../../constants';
import { RootState } from '../../../../store';
import { loadSessionDetails } from '../../../../store/sessionDataSlice';
import { formatCurrency, mapErrorToApiMessage } from '../../../../utils';
import ConfirmDialog from '../../../ConfirmDialog';
import { displayConfirm } from '../../../../store/confirmDialogSlice';
import { updatePageTitle } from '../../../../store/appDataSlice';
import { SessionController } from '.././SessionController';
import { InvestmentService } from '../../../../services/Investment';
import { LayoutBoard } from '../../../layouts/LayoutBoard';
import { InvestmentsColumns } from './InvestmentsColumns';
import { SessionService } from '../../../../services/Session';

export function Investments() {
	/**
	 * Subscriptions
	 */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const params = useParams();

	dispatch(updatePageTitle('Investissements'));

	const selectedSession = useSelector((state: RootState) => state.sessionData.value);
	const selectedInvestment = useSelector((state: RootState) => state.investmentData.value);

	const { open: isOpenConfirm } = useSelector((state: RootState) => state.confirmDialog.value);

	/**
	 * States
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	/**
	 * functionnals methodes
	 */

	const remove = async (id: string) => {
		try {
			const resp = await SessionController.delete(id);
			if (resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: 'investissement supprimée',
						open: true,
					})
				);
			}
		} catch (error: any) {
			console.error(error);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: mapErrorToApiMessage(error.response.data?.message) || 'Suppression echouée',
					open: true,
				})
			);
		}
	};

	const handleItemRemoveClick = (e: any, row: any) => {
		dispatch(loadSessionDetails(row));
		dispatch(displayConfirm({ open: true }));
	};
	const onDialogClose = (e: any) => {
		dispatch(displayConfirm({ open: false }));
	};

	const onDialogOk = async (e: any) => {
		if (selectedInvestment.id) await remove(selectedInvestment.id);
		loadData();
		dispatch(displayConfirm({ open: false }));
	};

	const handleItemEditClick = (e: any, id: string) => {
		e.preventDefault();
		navigate(`/sessions/${params.session}/investments/${id}`);
	};

	const loadSessionData = () => {
		SessionService.get(params.session)
			.then((resp: any) => {
				dispatch(loadSessionDetails(resp.data));
			})
			.catch((error) => {
				console.error(error);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Erreur  lors du chargement des details la session',
						open: true,
					})
				);
			});
	};

	const loadData = async () => {
		setIsLoading(true);
		loadSessionData();
		const result = await InvestmentService.getBySession(params.session as string);
		if (result?.status === 200) {
			setRows(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generatedCA = ({ partPrice, totalParts, rate }: any) => {
		const investedAmount = (partPrice as number) * (totalParts as number);
		const percentage = (rate as number) / 100;
		const result = investedAmount + investedAmount * percentage;
		return result ? formatCurrency(result, 2, true) : null;
	};

	const totalAmount = ({ partPrice, totalParts }: any) => {
		const result = (partPrice as number) * (totalParts as number);
		return result ? formatCurrency(result, 2, true) : null;
	};

	return (
		<LayoutBoard>
			<ConfirmDialog
				open={isOpenConfirm}
				title='Suppression investissement'
				content='Voulez-vous vraiment supprimer cet investissement ?'
				okBtnLabel='Oui'
				closeBtnLabel='Non'
				onClose={onDialogClose}
				onOk={onDialogOk}
			/>
			<Box sx={{ m: 2 }}>
				<Card variant='outlined' sx={{ pt: 2, pl: 2, pb: 2 }}>
					<div style={{ display: 'flex' }}>
						<Typography variant='h6'>{selectedSession.name}</Typography>
					</div>
					<div style={{ display: 'flex', gap: '20px' }}>
						<div>Parts : {formatCurrency(selectedSession.totalParts as number, 0)}</div>
						<div>Taux : {formatCurrency(selectedSession.rate as number, 0)}%</div>
						<div>Prix Part : {formatCurrency(selectedSession.partPrice as number, 2, true)}</div>
						<div>Cout total :{totalAmount(selectedSession)}</div>
						<div>Chiffre d'affaire: {generatedCA(selectedSession)}</div>
					</div>
				</Card>
			</Box>
			<Box
				sx={{
					width: '98%',
					backgroundColor: '#fff',
					m: 2,
				}}
			>
				<DataGrid
					autoHeight
					loading={isLoading}
					rows={rows}
					columns={InvestmentsColumns({
						handleItemEditClick,
						handleItemRemoveClick,
					})}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			</Box>

			<Fab
				onClick={() => navigate(`/sessions/${params.session}/investments/new`)}
				style={{
					position: 'absolute',
					bottom: 35,
					right: 35,
				}}
				color='primary'
				aria-label='add'
			>
				<AddIcon />
			</Fab>
		</LayoutBoard>
	);
}
