import axios from 'axios';

export class AppService {
	static async frontVersion() {
		return await axios({
			method: 'GET',
			url: '/manifest.json',
		});
	}
}
