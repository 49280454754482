import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { UserService } from '../../../services/User';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
	ROLES,
} from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	Button,
	Grid,
	LinearProgress,
	TextField,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import { ProfileUser } from '../../../types/user';
import { updatePageTitle } from '../../../store/appDataSlice';
import { LayoutBoard } from '../../layouts/LayoutBoard';

export function UserForm() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		await createUser(data);
		setIsLoading(false);
		//
	};

	const createUser = async (data: any) => {
		const resp = await UserService.create(data as ProfileUser);
		if (resp?.status === 201) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'session'),
					open: true,
				})
			);
			resetForm();
			navigate('/users');
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_FEMALE_CREATION_ERROR.replace('###', 'session'),
					open: true,
				})
			);
		}
	};

	const resetForm = () => {
		reset();
	};

	useEffect(() => {
		dispatch(updatePageTitle("Création d'un utilisateur "));
		// eslint-disable-next-line
	}, []);

	return (
		<LayoutBoard container={true}>
			<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
				{isLoading && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				)}
				Utilisateurs
				<Grid container sx={{ mb: 2 }}>
					<Grid item xs={8}></Grid>
					<Grid item xs={4} style={{ textAlign: 'right' }}>
						<Button type='button' onClick={() => navigate('/users')} variant='outlined'>
							Retour
						</Button>{' '}
						<Button type='reset' onClick={resetForm} variant='outlined'>
							Annuler
						</Button>{' '}
						<Button type='submit' variant='contained'>
							Enregistrer
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Nom'
							variant='outlined'
							{...register('lastname', { required: true })}
							error={Boolean(errors.lastname)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Prénom'
							type='text'
							variant='outlined'
							{...register('firstname', { required: true })}
							error={Boolean(errors.firstname)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='E-mail'
							type='email'
							variant='outlined'
							{...register('email', { required: true })}
							error={Boolean(errors.email)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Numéro de téléphone'
							type='text'
							variant='outlined'
							{...register('phone', { required: true })}
							error={Boolean(errors.phone)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth sx={{ mt: 1 }}>
							<InputLabel id='role'>Role</InputLabel>
							<Select
								labelId='role'
								id='role-select'
								{...register('role', { required: true })}
								value={2}
								error={Boolean(errors.role)}
								label='Investisseur'
							>
								<MenuItem value=''>
									<em>-- Selectionnez </em>
								</MenuItem>
								{ROLES.map(({ id, description }) => (
									<MenuItem value={id}>{description}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		</LayoutBoard>
	);
}
