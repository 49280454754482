import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NullableNumber, NullableString } from '../types';
export interface SessionDetails {
	id: NullableString;
	name: NullableString;
	rate: NullableNumber;
	partPrice: NullableNumber;
	totalParts: NullableNumber;
	description?: NullableString;
	slug?: NullableString;
	startDate?: NullableString;
	endDate?: NullableString;
	closed?: boolean;
}
const initialState = {
	value: {
		id: null,
		name: null,
		rate: 0,
		partPrice: 0,
		totalParts: 0,
		description: null,
		slug: null,
		startDate: null,
		endDate: null,
		closed: false,
	} as SessionDetails,
};

export const sessionDataSlice = createSlice({
	name: 'sessionData',
	initialState,
	reducers: {
		loadSessionDetails: (state, action: PayloadAction<SessionDetails>) => {
			state.value = action.payload;
		},
		getSelectedItem: (state, action: PayloadAction<SessionDetails>) => {
			state.value = action.payload;
		},
	},
});

export const { loadSessionDetails, getSelectedItem } = sessionDataSlice.actions;

export default sessionDataSlice.reducer;
