import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { formatDate, getUserRole } from '../../utils';

export const UserCard = ({ user }: any) => {
	const { firstname, lastname, email, phone, role, createdAt } = user;
	return (
		<Card>
			<CardContent>
				<div
					style={{
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							width: 250,
							height: 250,
							borderRadius: '50%',
							backgroundColor: '#BBDEFB',
							marginBottom: 12,
						}}
					></div>
				</div>
				<Typography variant='h5' sx={{ textAlign: 'center' }} color='text.primary'>
					{`${firstname} ${lastname}`}
				</Typography>
				<Typography sx={{ fontSize: 14, textAlign: 'center' }} color='text.secondary'>
					{getUserRole(role)}
				</Typography>

				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell align='right'>Nom : </TableCell>
								<TableCell align='left'>{lastname}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Prénom : </TableCell>
								<TableCell align='left'>{firstname}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>E-mail : </TableCell>
								<TableCell align='left'>{email}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Téléphone : </TableCell>
								<TableCell align='left'>{phone}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Date de création : </TableCell>
								<TableCell align='left'>{createdAt ? formatDate(createdAt) : '-'}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
};
