import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { VOID_FN, formatDate } from '../../utils';

export const SessionCard = ({ actionComponent = VOID_FN, session }: any) => {
	const { id, _id, name, description, totalParts, partPrice, startDate, endDate, rate } = session;
	return (
		<Card>
			<CardContent>
				<Typography variant='h5' sx={{ textAlign: 'left', p: 2 }} color='text.primary'>
					Session : {name}
				</Typography>
				<Typography sx={{ fontSize: 14, textAlign: 'center', p: 1 }} color='text.primary'>
					{description}
				</Typography>

				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell align='right'>Parts</TableCell>
								<TableCell align='right'>{totalParts}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Prix U. part</TableCell>
								<TableCell align='right'>{partPrice}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Taux</TableCell>
								<TableCell align='right'>{rate}%</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Debut</TableCell>
								<TableCell align='right'>{formatDate(startDate)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='right'>Fin</TableCell>
								<TableCell align='right'>{formatDate(endDate)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
			{actionComponent(id || _id)}
		</Card>
	);
};
