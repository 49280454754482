import { useMemo } from 'react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { PaletteMode, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { green } from '@mui/material/colors';
import { THEME_DARK, THEME_LIGHT } from '../../constants';
import { Auth } from '../../services/Auth';

export const AppTheme = ({ children }: any) => {
	let mode: PaletteMode;
	const sysPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)'); // user system
	const theme = useSelector((state: RootState) => state.appData.theme); // user preferene in app
	if (Auth.getUser()) {
		mode = theme as PaletteMode;
	} else {
		mode = sysPrefersDarkMode ? THEME_DARK : THEME_LIGHT;
	}
	const mdTheme = useMemo(
		() =>
			createTheme({
				palette: {
					primary: green,
					mode,
				},
			}),
		[mode]
	);
	return <ThemeProvider theme={mdTheme}> {children}</ThemeProvider>;
};
