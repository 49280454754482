import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';

export class CountersService {
	static getJWTHeaders() {
		return {
			'Access-Control-Allow-Origin': '*',
			Authorization: `Bearer ${Auth.getToken()}`,
		};
	}
	static async count(num: number) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'counters/' + num,
			headers: CountersService.getJWTHeaders(),
		});
	}

	static async userCount(userId: string) {
		// investments by user
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'counters/4/' + userId,
			headers: CountersService.getJWTHeaders(),
		});
	}

	static async userTotalAmount(userId: string) {
		// investments by user
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'counters/5/' + userId,
			headers: CountersService.getJWTHeaders(),
		});
	}
}
