import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material'
import { NotifyBox } from '../types/NotifyBox'

export interface AlertBoxState {
    value: NotifyBox
}

const initialState: AlertBoxState = {
    value: {
        severity: "success" as AlertColor,
        open: false,
        message: '',
        autoHideDuration :6000
      }
}

export const alertBoxSlice = createSlice({
  name: 'alertBox',
  initialState,
  reducers: {
    showAlertBox: (state, action: PayloadAction<NotifyBox>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { showAlertBox } = alertBoxSlice.actions

export default alertBoxSlice.reducer