import axios from 'axios';
import { APP_API_URL } from '../constants';
import { ProfileUser } from '../types/user';
import { Auth } from './Auth';
export class UserService {
	static getJWTHeaders() {
		return {
			'Access-Control-Allow-Origin': '*',
			Authorization: `Bearer ${Auth.getToken()}`,
		};
	}
	static async getAll() {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'users',
			headers: UserService.getJWTHeaders(),
		});
	}

	static async get(id: string) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'users/' + id,
			headers: UserService.getJWTHeaders(),
		});
	}

	static async create(user: ProfileUser) {
		return await axios({
			method: 'POST',
			url: APP_API_URL + 'users',
			data: user,
			headers: UserService.getJWTHeaders(),
		});
	}

	static async delete(id: string) {
		return await axios({
			method: 'DELETE',
			url: APP_API_URL + 'users/' + id,
			headers: UserService.getJWTHeaders(),
		});
	}
}
