import { Card, CardContent, Typography } from '@mui/material';

export function ChartWrapper({ title, children }: any) {
	return (
		<Card>
			<Typography sx={{ fontSize: 14, textAlign: 'center', p: 1 }} color='text.secondary'>
				{title}
			</Typography>
			<CardContent>{children}</CardContent>
		</Card>
	);
}
