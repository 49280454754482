import { configureStore } from '@reduxjs/toolkit';
import alertBoxReducer from './alertBoxSlice';
import confirmDialogReducer from './confirmDialogSlice';
import appDataReducer from './appDataSlice';
import sessionDataReducer from './sessionDataSlice';
import investmentDataReducer from './investmentDataSlice';
import profileDataReducer from './profileDataSlice';

export const store = configureStore({
	reducer: {
		alertBox: alertBoxReducer,
		confirmDialog: confirmDialogReducer,
		appData: appDataReducer,
		sessionData: sessionDataReducer,
		investmentData: investmentDataReducer,
		profileData: profileDataReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
