import { useEffect, useState } from 'react';
import { Counter } from '.';
import { DEFAULT_ALERT_ERROR } from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { useDispatch } from 'react-redux';
import { CountersService } from '../../../services/Counters';

export const Counter1 = () => {
	const [counter, setCounter] = useState(0);
	const dispatch = useDispatch();
	const loadData = async () => {
		CountersService.count(1)
			.then((resp: any) => {
				setCounter(resp.data.count);
			})
			.catch((res) => {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: ' Impossible de charger les données statistiques',
						open: true,
					})
				);
			});
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Counter title='Investissements' counter={counter} />;
};
