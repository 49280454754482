import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { Login } from '../components/pages/Auth/Login';
import { Register } from '../components/pages/Auth/Register';
import { Board } from '../components/pages/Board/Board';
import { Profile } from '../components/pages/Profile/Profile';
import { Sessions } from '../components/pages/Sessions/Sessions';
import { SessionForm } from '../components/pages/Sessions/SessionForm';
import { Investments } from '../components/pages/Sessions/Investments/Investments';
import { InvestmentForm } from '../components/pages/Sessions/Investments/InvestmentForm';
import { ResetPassword } from '../components/pages/Auth/ResetPassword';
import { RequestResetPassword } from '../components/pages/Auth/RequestResetPassword';
import { Auth } from '../services/Auth';
import { Opportunities } from '../components/pages/Opportunities/Opportunities';
import { SessionDetail } from '../components/pages/Sessions/SessionDetail';
import { InvestmentFormSelf } from '../components/pages/Sessions/Investments/InvestmentFormSelf';
import { MyInvestments } from '../components/pages/Investments/MyInvestments';
import { AllInvestments } from '../components/pages/Investments/AllInvestments';

import HomeIcon from '@mui/icons-material/Home';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import GroupIcon from '@mui/icons-material/Group';
import { RouteItem } from '../types/route';
import { Activation } from '../components/pages/Auth/Activation';
import { About } from '../components/pages/About/About';
import InfoIcon from '@mui/icons-material/Info';
import { Conditions } from '../components/pages/About/Conditions';
import { ADMIN, INVESTOR } from '../constants';
import { Users } from '../components/pages/Users/Users';
import { UserForm } from '../components/pages/Users/UserForm';
import { UserDetail } from '../components/pages/Users/UserDetail';

const user = Auth.getUser();
export const ROUTES: RouteItem[] = [
	{
		id: 1,
		path: '/',
		element: user ? <Board /> : <Login />,
		public: true,
		showMenu: false,
		icon: <HomeIcon />,
		label: 'Accueil',
	},
	{
		id: 2,
		path: '/board',
		element: <Board />,
		roles: [ADMIN, INVESTOR],
	},
	{
		id: 3,
		path: '/login',
		element: <Login />,
		public: true,
	},
	{
		id: 4,
		path: '/register',
		element: <Register />,
		public: true,
	},
	{
		// activate user account
		id: 5,
		path: '/auth/activation/:activationToken',
		element: <Activation />,
		public: true,
	},
	{
		id: 6,
		path: '/auth/reset-password',
		element: <RequestResetPassword />,
		public: true,
	},
	{
		id: 7,
		path: '/auth/reset-password/:resetToken',
		element: <ResetPassword />,
		public: true,
	},
	{
		id: 8,
		path: '/profile',
		element: <Profile />,
		roles: [ADMIN, INVESTOR],
	},
	{
		id: 9,
		path: '/sessions',
		element: <Sessions />,
		roles: [ADMIN],
		showMenu: true,
		icon: <AddBusinessIcon />,
		label: 'Sessions',
	},
	{
		id: 10,
		path: '/sessions/new',
		element: <SessionForm />,
		roles: [ADMIN],
	},
	{
		id: 11,
		path: '/sessions/:id',
		element: <SessionForm />,
		roles: [ADMIN],
	},
	{
		id: 12,
		path: '/sessions/:session/investments',
		element: <Investments />,
		roles: [ADMIN],
	},
	{
		id: 13,
		path: '/sessions/:session/investments/new',
		element: <InvestmentForm />,
		roles: [ADMIN],
	},
	{
		id: 14,
		path: '/sessions/:session/investments/:id',
		element: <InvestmentForm />,
		roles: [ADMIN],
	},
	{
		id: 15,
		path: '/sessions/detail/:id',
		element: <SessionDetail />,
		roles: [ADMIN, INVESTOR],
	},
	{
		id: 16,
		path: '/opportunities',
		element: <Opportunities />,
		roles: [INVESTOR],
		showMenu: true,
		icon: <CardTravelIcon />,
		label: 'Opportunités',
	},
	{
		id: 17,
		path: '/opportunities/:session',
		element: <InvestmentFormSelf />,
		roles: [INVESTOR],
	},
	{
		id: 18,
		path: '/my/investments',
		element: <MyInvestments />,
		roles: [INVESTOR],
		showMenu: true,
		icon: <CurrencyExchangeIcon />,
		label: 'Mes investissement',
	},
	{
		id: 19,
		path: '/investments',
		element: <AllInvestments />,
		roles: [ADMIN],
		showMenu: true,
		icon: <CurrencyExchangeIcon />,
		label: 'Investissement',
	},
	{
		id: 20,
		path: '/users',
		element: <Users />,
		roles: [ADMIN],
		showMenu: true,
		icon: <GroupIcon />,
		label: 'Utilisateurs',
	},
	{
		id: 21,
		path: '/users/new',
		element: <UserForm />,
		roles: [ADMIN],
	},
	{
		id: 22,
		path: '/users/:id',
		element: <UserDetail />,
		roles: [ADMIN],
	},
	{
		id: 23,
		path: '/about',
		element: <About />,
		roles: [ADMIN, INVESTOR],
		showMenu: true,
		icon: <InfoIcon />,
		label: 'A propos',
	},
	{
		id: 24,
		path: '/cgu',
		element: <Conditions />,
		public: true,
	},
];

const router = createBrowserRouter(
	(user
		? ROUTES.filter(
				(routeItem) =>
					routeItem.public ||
					(routeItem.roles &&
						routeItem.roles.length &&
						routeItem.roles.includes(user.role as number))
		  )
		: ROUTES.filter((routeItem) => !!routeItem.public)) as RouteObject[]
);

export default router;
