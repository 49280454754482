import { useEffect, useRef, useState } from 'react';
import { Bar, getDatasetAtEvent } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	Tooltip,
	Legend,
	Colors,
	BarElement,
	CategoryScale,
	LinearScale,
} from 'chart.js';
import { ChartService } from '../../services/Chart';
import { useDispatch } from 'react-redux';
import { showAlertBox } from '../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR } from '../../constants';
import { ChartWrapper } from '.';

export function Chart5({ id }: any) {
	ChartJS.register(BarElement, Tooltip, Legend, Colors, CategoryScale, LinearScale);

	const chartRef = useRef(null);
	const [pieChartData, setPieChartData] = useState({
		labels: [],
		data: [],
	} as any);

	const dispatch = useDispatch();

	const configChartData = {
		labels: pieChartData.labels,
		datasets: [
			{
				id: 2,
				label: 'Montant investi',
				data: pieChartData.data,
			},
		],
	};

	const onClick = (event: any) => {
		console.log(getDatasetAtEvent(chartRef.current as any, event));
	};

	const loadData = async () => {
		ChartService.chart5(id)
			.then((resp: any) => {
				setPieChartData(resp.data);
			})
			.catch((res) => {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: " Impossible d'afficher la liste",
						open: true,
					})
				);
			});
	};

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ChartWrapper title='Investissements mensuels'>
			{!pieChartData.data.length ? (
				<div style={{ textAlign: 'center' }}> Aucune donnée disponible</div>
			) : (
				<Bar
					ref={chartRef}
					data={configChartData}
					options={{
						plugins: {
							legend: {
								position: 'right',
							},
						},
					}}
					onClick={onClick}
				/>
			)}
		</ChartWrapper>
	);
}
