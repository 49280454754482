import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageTitle } from '../../../store/appDataSlice';
import { RootState } from '../../../store';
import { SessionController } from '../Sessions/SessionController';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR, DEFAULT_ALERT_SUCCESS } from '../../../constants';
import { mapErrorToApiMessage } from '../../../utils';
import { loadSessionDetails } from '../../../store/sessionDataSlice';
import { displayConfirm } from '../../../store/confirmDialogSlice';
import { InvestmentService } from '../../../services/Investment';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import ConfirmDialog from '../../ConfirmDialog';
import { MyInvestmentsColumns } from './MyInvestmentsColumns';

export function MyInvestments() {
	/**
	 * Subscriptions
	 */
	const dispatch = useDispatch();

	dispatch(updatePageTitle('Mes investissements'));

	const selectedInvestment = useSelector((state: RootState) => state.investmentData.value);

	const { open: isOpenConfirm } = useSelector((state: RootState) => state.confirmDialog.value);

	/**
	 * States
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	/**
	 * functionnals methodes
	 */

	const remove = async (id: string) => {
		try {
			const resp = await SessionController.delete(id);
			if (resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: 'investissement supprimée',
						open: true,
					})
				);
			}
		} catch (error: any) {
			console.error(error);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: mapErrorToApiMessage(error.response.data?.message) || 'Suppression echouée',
					open: true,
				})
			);
		}
	};

	const handleItemRemoveClick = (e: any, row: any) => {
		dispatch(loadSessionDetails(row));
		dispatch(displayConfirm({ open: true }));
	};
	const onDialogClose = (e: any) => {
		dispatch(displayConfirm({ open: false }));
	};

	const onDialogOk = async (e: any) => {
		if (selectedInvestment.id) await remove(selectedInvestment.id);
		loadData();
		dispatch(displayConfirm({ open: false }));
	};

	const loadData = async () => {
		setIsLoading(true);
		const result = await InvestmentService.getMine();
		if (result?.status === 200) {
			setRows(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LayoutBoard>
			<ConfirmDialog
				open={isOpenConfirm}
				title='Suppression investissement'
				content='Voulez-vous vraiment supprimer cet investissement ?'
				okBtnLabel='Oui'
				closeBtnLabel='Non'
				onClose={onDialogClose}
				onOk={onDialogOk}
			/>

			<Box
				sx={{
					width: '98%',
					backgroundColor: '#fff',
					m: 2,
				}}
			>
				<DataGrid
					autoHeight
					loading={isLoading}
					rows={rows}
					columns={MyInvestmentsColumns({
						handleItemRemoveClick,
					})}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			</Box>
		</LayoutBoard>
	);
}
