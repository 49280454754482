import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { InvestmentService } from '../../../../services/Investment';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_EDIT_ERROR,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
	LABEL_FEMALE_EDIT_SUCCESS,
} from '../../../../constants';
import { showAlertBox } from '../../../../store/alertBoxSlice';
import { Button, Grid, LinearProgress, TextField, Box, Typography } from '@mui/material';
import { InvestmentItem } from '../../../../types/InvestmentItem';
import { updatePageTitle } from '../../../../store/appDataSlice';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { loadInvestmentDetails } from '../../../../store/investmentDataSlice';
import { LayoutBoard } from '../../../layouts/LayoutBoard';
import { AxiosError } from 'axios';
import { ServerError } from '../../../../types';
import { SessionService } from '../../../../services/Session';
import { loadSessionDetails } from '../../../../store/sessionDataSlice';

export function InvestmentForm() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const [investorName, setInvestorName] = useState('');
	const params = useParams();
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const sessionDetails = useSelector((state: RootState) => state.sessionData.value);

	const {
		reset,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		if (params?.id) {
			updateInvestment({ id: params.id, ...data });
		} else {
			createInvestment(data);
		}
		setIsLoading(false);
		//
	};

	const createInvestment = async (data: any) => {
		try {
			const resp = await InvestmentService.create(params.session as string, data as InvestmentItem);
			if (resp?.status && resp?.status === 201) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'investissement'),
						open: true,
					})
				);
				resetForm();
				navigate(`/sessions/${params.session}/investments`);
			}
		} catch (error) {
			console.error(error);
			const serverError = (error as AxiosError).response?.data as ServerError;
			const message = serverError?.message
				? serverError.message
				: LABEL_FEMALE_CREATION_ERROR.replace('###', 'investissement');
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const updateInvestment = async (data: any) => {
		const resp = await InvestmentService.update(params.session as string, data as InvestmentItem);
		if (resp?.status === 200) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_EDIT_SUCCESS.replace('###', 'investissement'),
					open: true,
				})
			);
			resetForm();
			navigate(`/sessions/${params.session}/investments`);
		} else {
			const message = resp?.data?.message
				? resp?.data?.message
				: LABEL_EDIT_ERROR + 'investissement';
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const loadSessionData = () => {
		SessionService.get(params.session)
			.then((resp: any) => {
				dispatch(loadSessionDetails(resp.data));
			})
			.catch((error) => {
				console.error(error);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Erreur  lors du chargement des details la session',
						open: true,
					})
				);
			});
	};

	const preFillForm = (investment: InvestmentItem) => {
		const { investor, parts, investorName: name } = investment;
		setValue('parts', parts);
		setValue('investor', investor);
		setInvestorName(name || '');
	};

	const resetForm = () => {
		reset();
	};

	useEffect(() => {
		const { id } = params;
		async function loadDetails(id: string) {
			const resp = await InvestmentService.get(params.session as string, id);
			if (resp?.status === 200) {
				dispatch(loadInvestmentDetails(resp.data));
				preFillForm(resp.data as InvestmentItem);
			}
		}
		loadSessionData();
		if (Boolean(id)) {
			dispatch(updatePageTitle("Modification d'un investissement"));
			loadDetails(id as string);
		} else {
			dispatch(updatePageTitle("Création d'un investissement"));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<LayoutBoard container={true}>
			<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
				{isLoading && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				)}
				<Typography>Investissement dans la session {sessionDetails.name}</Typography>
				<Grid container style={{ marginBottom: 50 }}>
					<Grid item xs={4} style={{ textAlign: 'right' }}>
						<Button
							type='button'
							onClick={() => navigate(`/sessions/${params.session}/investments`)}
							variant='outlined'
						>
							Retour
						</Button>{' '}
						<Button type='reset' onClick={resetForm} variant='outlined'>
							Annuler
						</Button>{' '}
						<Button type='submit' variant='contained'>
							Enregistrer
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					{!!investorName && (
						<Grid item xs={12}>
							<TextField
								fullWidth
								label='Investisseur'
								variant='outlined'
								value={investorName}
								disabled={true}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Investisseur'
							variant='outlined'
							type='email'
							disabled={Boolean(params.id)}
							{...register('investor', { required: true })}
							error={Boolean(errors.investor)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Nombre de parts'
							type='number'
							variant='outlined'
							{...register('parts', { required: true })}
							error={Boolean(errors.parts)}
						/>
					</Grid>
				</Grid>
			</Box>
		</LayoutBoard>
	);
}
