import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ConfirmProps {
    open: boolean;
    title?: string;
    message?: string;
}

export interface ConfirmDialogState {
    value: ConfirmProps
}

const initialState: ConfirmDialogState = {
    value:{open: false, title:'', message: ''}
}

export const confirmDialogSlice = createSlice({
  name: 'confirmDialog',
  initialState,
  reducers: {
    displayConfirm: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { displayConfirm } = confirmDialogSlice.actions

export default confirmDialogSlice.reducer