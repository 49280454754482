import React, { useEffect } from 'react';
import { Box, CssBaseline, Grid, LinearProgress, Paper } from '@mui/material';
import { Copyright } from '../../Copyright';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_ACCOUNT_ACTIVATION_ERROR,
	LABEL_ACCOUNT_ACTIVATION_SUCCESS,
} from '../../../constants';
import { RootState } from '../../../store';
import Alert from '@mui/material/Alert';
import { Logo } from '../../common/Logo/Index';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from '../../../services/Auth';
import { AxiosError } from 'axios';
import { ServerError } from '../../../types';

export function Activation() {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;

	const activateAccount = async (token: string) => {
		setIsLoading(true);
		//
		try {
			const resp = await Auth.activation(token);
			if (resp?.status && resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: LABEL_ACCOUNT_ACTIVATION_SUCCESS,
						open: true,
					})
				);
				navigate('/login');
			}
		} catch (error) {
			console.error(error);
			const serverError = (error as AxiosError).response?.data as ServerError;
			const message = serverError?.message ? serverError.message : LABEL_ACCOUNT_ACTIVATION_ERROR;
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}

		setIsLoading(false);
	};
	useEffect(() => {
		activateAccount(params.activationToken as string);
		// eslint-disable-next-line
	}, []);
	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid item component={Paper} elevation={6} square sm={12} md={12}>
				<Box
					sx={{
						my: 2,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Logo />
					{isOpen && (
						<Box sx={{ width: '100%', mt: 2 }}>
							<Alert
								variant='filled'
								onClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_ERROR, open: false }))}
								severity={severity}
							>
								{message}
							</Alert>
						</Box>
					)}
					<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Grid>
		</Grid>
	);
}
