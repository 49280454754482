import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';

export class ChartService {
	static getJWTHeaders() {
		return {
			'Access-Control-Allow-Origin': '*',
			Authorization: `Bearer ${Auth.getToken()}`,
		};
	}
	static async chart1To3(num: number) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'charts/chart-' + num,
			headers: ChartService.getJWTHeaders(),
		});
	}
	static async chart4(session: string) {
		// pie chart investments by session, availables / taken
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'charts/chart-4/' + session,
			headers: ChartService.getJWTHeaders(),
		});
	}

	static async chart5(userId: string) {
		// pie chart investments by session, availables / taken
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'charts/chart-5/' + userId,
			headers: ChartService.getJWTHeaders(),
		});
	}
}
